import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppManager from "services/app-manager"
import Layout from "components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "components/typography"
import { ListHeaderProvider } from "components/content/list-header"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import ProductCategoryPageTopSection from "components/content/product-category-page-top-section"
import { SectionTextDivider, SectionTextSubdivider } from "components/content/product-category-page-section-header"

const TermDepositsView = () => {
  const dateUpdated = AppManager.getInstance().getProductCategoryDateUpdated("td")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated ? dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4) : ""

  const isWide = useMediaQuery("(min-width:768px)")
  const chunkSize = isWide ? 4 : 2
  const features: any[] = [
    { name: "Best Rates", urlSlug: "best-term-deposit-rates", img: "images/icon_best_rate.png" },
    { name: "3 Month Rates", urlSlug: "best-3-month-term-deposit-rates", img: "images/icon_term_deposit_3.png" },
    { name: "6 Month Rates", urlSlug: "best-6-month-term-deposit-rates", img: "images/icon_term_deposit_6.png" },
    { name: "12 Month Rates", urlSlug: "best-12-month-term-deposit-rates", img: "images/icon_term_deposit_12.png" },
    // {name: "24+ month", urlSlug: "best-24-month-term-deposit-rates", img: "images/icon_term_deposit_24.png" },
  ]
  const featureLines = chunkifyArray(features, chunkSize)

  const tdProviders: object = AppManager.getInstance().getReducedTermDepositProviders()
  const mainItems = Object.values(tdProviders)
    .filter((x: any) => x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(tdProviders)
    .filter((x: any) => !x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  const queryResult = useStaticQuery(graphql`
    {
      allContentfulContentQa {
        nodes {
          slug
          qa {
            qa
          }
        }
      }
    }
  `)
  const qa = queryResult.allContentfulContentQa.nodes.find((x: any) => x.slug === "/term-deposits")

  let maxRate = Number.MIN_VALUE

  Object.values(tdProviders).forEach((provider: any) => {
    if (provider.maxRate > maxRate) {
      maxRate = provider.maxRate
    }
  })

  let description = "Get a better term deposit deal from your bank and across the market. Rates up to "
  description += (maxRate * 100).toFixed(2) + "% p.a."

  return (
    <div>
      <div>
        <ProductCategoryPageTopSection productCategory="td" dateString={dateString} pageHeading={`Compare term deposits`} categoryDescription={description} />
      </div>
      <div>
        <div style={{ marginTop: "60px", marginBottom: "130px" }}>
          <SectionTextDivider heading="Compare term deposits by feature" text="Compare term deposits offering great rates for different term lengths." />
          <MapToDiv.Features features={featureLines} />
        </div>
        {/* <TopPicks /> */}
      </div>
      <div style={{ marginTop: "110px" }} id="providers">
        <SectionTextDivider heading="Compare leading term deposit brands" text="Compare term deposit offers from the major banks and other popular brands." />
        <SectionTextSubdivider subheading="Majors" text="Big banks with extensive nationwide networks that provide a comprehensive range of services" />
        <MapToDiv.Banks banks={majors} productCategory="term-deposit-rates" />
        <SectionTextSubdivider subheading="Challengers" text="Regional and international banking institutions that offer financial services" />
        <MapToDiv.Banks banks={popular} productCategory="term-deposit-rates" />
      </div>

      <div>
        {qa && (
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            {/* {<Markdown md={qa.qa.qa} isArticle={true} />} */}
            <Faq faqContent={qa.qa.qa} />
          </div>
        )}
        {/* <TermDepositDisclaimer isDisclaimerOpen isProvider /> */}
      </div>
    </div>
  )
}

const TermDeposits = () => {
  const tdProviders: object = AppManager.getInstance().getReducedTermDepositProviders()
  let maxRate = Number.MIN_VALUE
  Object.values(tdProviders).forEach((provider: any) => {
    if (provider.maxRate > maxRate) {
      maxRate = provider.maxRate
    }
  })

  return (
    <Layout title={`Term Deposit Rates Comparison - Rates up to ${(maxRate * 100).toFixed(2)}% p.a. | Stay or Go`} description="Compare term deposit offers from all the major banks and popular brands." image="/video/StayOrGo_Animation_01.jpf" urlSlug="term-deposits" includeMozoDisclaimer>
      <div className="container-center">
        <div className="home-container">
          <TermDepositsView />
        </div>
      </div>
    </Layout>
  )
}

export default TermDeposits
